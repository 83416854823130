<template>
  <base-main-container>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="68">
        AI is preparing your resource
      </v-progress-circular>
    </v-overlay>

    <template v-slot:centered>
      <v-form>
        <v-card class="my-card rounded-lg" elevation="0">
          <v-card-title>Story Problems for Math Facts </v-card-title>
          <v-card-text>
            <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="itemType"
              :items="itemtypes"
              item-text="Short"
              label="Type"
              outlined
              clearable
             
             return-object
            />
            <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="numberRange"
              :items="['Wole numbers less than 10', 
                       'Whole numbers less than 20',
                       'Whole numbers less than 50',
                       'Whole numebers less than 100',
                       'Whole numbers less than 500', 
                       'Decimal numbers less than 10',
                      'Decimal numbers less than 100', 
                      'Simple fractions ']"
              label="Choose number range"
              outlined
              clearable
            />
           <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="complexity"
              :items="['Easy', 'On grade leve', 'Difficult', 'For Gifted']"
              label="Complexity"
              outlined
              clearable
            />
             <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="grade"
              :items="['K','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11','12']"
              label="Suitable for Grade level"
              outlined
              clearable
            />


            <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="quizItemCount"
              :items="['1', '2', '3', '4', '5', '6', '7', '8', '10', '15']"
              label="Numer of Questions"
              outlined
              clearable
            />

             <v-text-field
      v-model="culture"
      class="ml-2"
      label="Culture/Context/Student Bacgrounds"
      hint="Optional"
      outlined
    />

          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>

            <v-btn :disabled="!itemType" color="#212121" @click="sendRequest()">
              <h3 v-if="!itemType">Generate please</h3>
              <h3 v-else style="color: white">Generate please</h3>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
    <v-dialog>

      
    </v-dialog>
    <v-snackbar v-model="showSnackbar" :timeout="6000" color="warning" top>
      {{ snackbarMessege }}
    </v-snackbar>
  </base-main-container>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";

import { firebaseAuth } from "../../firebase";
import { db } from "../../firebase";

export default {
  data() {
    return {
      what: "Story Problems",
      topic: null,
      grade: null,
      itemType:null,
      isLoading: false,
      numberRange:null,
      complexity:null,
      culture:null,
      quizItemCount: null,

      showSnackbar: false,
      snackbarMessege: "",
      itemtypes:[
        {Key:"JRU", Short:"Joining-Result Unknown (5+4=?)", 
        Objective:"when two numbers given, students should be able to find the sum (Joining-Result Unknown)"
      ,sample:"Sally has 5 cats and her friend Tom has 3 dogs. How many pets do they have in all?",
       comprehension:"Do the friends have moreor less than 5 pets?"},
        {Key:"JCU", Short:"Joning-Change Unknown (5+?=9) ", 
        Objective:"when the sum of a number and an unknown given, students should be able to find the unknown (Joning-Change Unknown)"
        ,sample:"There are 2 birds sitting on a tree branch. More birds came to join them. Now, there are 6 birds altogether. How many birds came to the branch?",
      comprehension:"Are there more or less than 6 birds on the branch?"},
        {Key:"JSU", Short:"Joining-Start Unknown (?+5=9)", 
        Objective:"when the sum of an unknown and a number given, students should be able to find the unknown (Joining-Start Unknown)"
      ,sample:"Atalia saw some ants on a picnic table. Then 15 more ants crawled onto the table. Now there are 40 ants on the table. How many ants did she see first?",
      comprehension:"Did Atalia see more or less than 40 ants at first?"},
       //next section
      {Key:"SRU", Short:"Separating-Result Unknown (9-4=?)", 
        Objective:"when two numbers given, students should be able to find the difference (Separating-Result Unknown)"
      ,sample:"A great white shark had 85 teeth. Later, it lost 29 teeth. How many teeth does the shark have now?",
      comprehension:"Does the shark have more or less than 85 teeth?"},
        {Key:"SCU", Short:"Separating-Change Unknown (9-?=5)", 
        Objective:"when the difference of a number and and unknown given, students should be able to find the unknown (Separating-Change Unknown)"
      ,sample:"The cafeteria made 98 pancakes. The children ate some of them. Now there are 63 pancakes left. How many pancakes did the children eat?",
      comprehension:"Did the children eat more or less than 98 pancakes?"},
        {Key:"SSU", Short:"Separating-Start Unknown (?-4=5)", 
        Objective:"when the difference of an unknown and a number given, students should be able to find the unknown (Separating-Start Unknown)"
      ,sample:"The garden store had some plants. They sold 94 of them. Now they have 48 plants How many plants did they have at first?",
      comprehension:"Did the garden store have more or less than 94 plants at first?"},
      //next section
     {Key:"PPW-WU", Short:"Part, Part, Whole- Whole Unknown (6+2+3=?)", 
     Objective:"when two or more numbers given, students should be able to find the sum (Part, Part, Whole- Whole Unknown)"
    ,sample:"Georgina has 11 red crayons, 9 blue crayons, and 14 green crayons. How many crayons does Georgina have?",
      comprehension:"Does Georgina have more or less than 14 crayons?"},
     {Key:"PPW-PU", Short:"Part, Part, Whole- Part Unknown (6+2+?=11)", 
     Objective:"when the sum  of two or more numbers, students should be able to find the sum (Part, Part, Whole- Part Unknown)"
    ,sample:"There are 133 frosted animal crackers. 75 of them are pink and the rest are white. How many frosted animal crackers are white?",
      comprehension:"Are more or less than 133 of the animal crackers white?"},
     //next section
      {Key:"CDU", Short:"Comparing Difference Unknown (6-4=?)", 
     Objective:"when two numbers given, students should be able to find how much the bigger number is more than the smaller number (Comparing Difference Unknown)"
    ,sample:"Alyssa recycled 52 papers in her homework folder. Destiny recycled 16 papers in her homework folder. How many more papers does Alyssa have than Destiny?",
      comprehension:""},
     {Key:"CQU", Short:"Comparing Quantity Unknown (4+2=?)", 
     Objective:"when the a number and another number bigger than the first number given, students should be able to find the sum (Comparing Quantity Unknown)"
    ,sample:"Alexis's tomato plant is 38 centimeters tall. Michael's tomato plant is 59 centimeters taller than Alexis's. How tall is Michael's tomato plant?",
      comprehension:""},
     {Key:"CRU", Short:"Comparing Referent Unknown (6-4=?)", 
     Objective:"when two numbers given, students should be able to find the difference (Comparing Referent Unknown)"
    ,sample:"Timmy recorded 62 sunny days and 49 rainy days this week. How many more sunny days than rainy days did Timmy record?",
      comprehension:""},
      //next 
     {Key:"PD", Short:"Parts Divided ", 
     Objective:"when a dividend and divisor given, students should be able to find the quotient"
    ,sample:"If there are 48 astronauts on a space station and they equally divide themselves into 6 teams, how many astronauts will be on each team??",
      comprehension:"Does each team of astronauts have more or less than 48 team members?"},
     {Key:"M", Short:"Multiplication ", 
     Objective:"when a multiplier and a multiplicand, students should be able to find the product"
    ,sample:"Ms. Dana bought 9 boxes of mettalic space pencils with 8 pencils  in each box. How many mettalic space pencils  did she buy for her students?",
      comprehension:"Did the art class buy more or less than 9 pencils?"}, 
      ],
    };
  },
  computed: {
   
  },

  methods: {
    async sendRequest() {
      this.isLoading = true;

     
      this.grade = this.grade
        ? "Yes, this quiz should be suitable for grade level: " + this.grade
        : "no specific grade level";
     
      this.quizItemCount = this.quizItemCount
        ? this.quizItemCount
        : "any number of questions";

        this.complexity = this.complexity
        ? "Please prepare items for students "+this.complexity
        : "I dont have a preference"
        
        this.culture = this.culture
        ? "Please prepare items for students "+this.culture 
        : "I dont have a preference"

        this.numberRange = this.numberRange
        ? this.numberRange
        : "any whole numbers"
     
      let uid = firebaseAuth.currentUser.uid;
      let newresource = {
        what: "Math Story Problems",
        topic: this.itemType.Key,
        grade: this.grade,
        lastsaved: Date.now(),
        promt: [
          {"role": "system", "content": "You are an excellent educator and teacher."},
          {"role": "user","content":"I need help to create a worsheet to teach simple math facts using story problems. "},
          {"role": "assistant","content": "I can help you for this task, can you tell me what specific learning outcome (or learning objective) you will be teaching"},
          {"role": "user", "content": this.itemType.Objective },
          {"role": "assistant","content": "Thank you for letting me know the topic. I can generate items similar to a given sample item, do you have any sample item "},
          {"role": "user", "content":"Yes, I can provide you a sample item but as long as your generated items measure the learning standards, they dont need to be very similar to this sample. Here is a sample: " +this.itemType.sample },
          {"role": "assistant","content": "Thank you. I can add a hint to each question, can you give me a sample hint for this sample problem so that I can add hints similar to your sample"},
          {"role": "user", "content": "Yes, you can add hints. For the sample problem that I gave you, I would put this hint "+this.itemType.comprehension},
          {"role": "assistant","content": "Thank you. Do you need me to create questions for a specific grade level?"},
          {"role": "user", "content": this.grade },
          {"role": "assistant","content":"Thank you. How complex or easy should I make these questions "},
          {"role": "user", "content": this.complexity },
          {"role": "assistant","content":"Thank you. As and AI system, I have a vast knowledge about cultures and I can prepare items relating students cultures and backgrounds which will increase their engagement. Can you tell me about your students culture so that I can create items related with their culture and backgrounds"},
          {"role": "user", "content": this.culture },
          {"role": "assistant","content":"how many questions would you like to include in this quiz"},
          {"role": "user", "content": this.quizItemCount },
          {"role": "assistant","content":"Thank you, I have all the details that I need to create a quiz for your studetns. Do you have any other details before I generate the quiz?"},
          {"role": "user","content": "Please make sure using "+this.numberRange+". That is all the context, please generate this quiz/worksheet for me"}
        ],
        uid: uid,
      };
      try {
        const userRef = db.collection("customers").doc(uid);
        const subcollectionRef = userRef.collection("saved");

        const docRef = await subcollectionRef.add(newresource);
        //console.log(docRef.id);
        newresource.id = docRef.id;
      } catch (error) {
        console.error("Error adding document:", error);
      }

      const functions = getFunctions();

      try {
        const functionRef = httpsCallable(functions, "callopenai");
        functionRef(newresource);

        this.isLoading = false;

        this.$router.push("/mydocs");
      } catch (error) {
        this.isLoading = false;
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
      }
    },

    clearSelections() {
      this.itemType = null;
      this.grade = null;
      this.numberRange = null;
      this.quizItemCount = null;
      this.complexity = null
    },

    
  },
};
</script>

<style scoped>
@media (min-width: 600px) {
  .my-card {
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    width: 800px;
  }
}
</style>




